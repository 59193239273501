@import '../../assets/styles/theme.scss';

.m-error-modal {
  .m-modal {
    text-align: center;
    color: $iz-dark-blue;
  }

  .m-modal-title {
    @extend .m-modal;
    font-size: 30px;
  }

  .m-modal-text {
    @extend .m-modal;
    font-size: 16px;
    margin-bottom: 40px;
  }

  .m-modal-btn:disabled {
    display: none;
  }

  .ant-modal-confirm-body {
    display: block !important;
  }

  .ant-modal-confirm-btns {
    text-align: center !important;
  }

  .m-modal-btn {
    height: 48px;
    min-width: 167px;
    width: auto;
    border: 1px solid $iz-light-blue;
    border-radius: 5px;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .m-modal-cancel-btn {
    @extend .m-modal-btn;
    color: $iz-light-blue;
  }

  .m-modal-ok-btn {
    @extend .m-modal-btn;
    background: $iz-light-blue;
  }
  .ant-modal-confirm-btns {
    width: max-content;
  }

  .ant-modal-content {
    width: fit-content;
  }

  .ant-modal-confirm-btns {
    margin-inline-start: 20px;
  }
}
