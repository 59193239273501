$--o-color-text-brand: #183362;
$--o-color-text-brand-secondary: #009dd3;
$--o-color-text-secondary: #002652;
$--o-color-text-information: #898989;
$--o-color-text-information-onsecondary: #686868;
$--o-color-text-disabled: #a3a9b7;
$--o-color-border: #d0d0d0;
$--o-color-icon: #29292f;
$--o-color-bg-canvas: #f6f7f8;

.ant-select-item-option-active {
  background-color: transparent !important;
}

.ant-select-dropdown .ant-select-item:first-of-type {
  height: 35px !important;
}

.ant-select-dropdown .ant-select-item {
  padding: 5px 12px !important;
}

.ant-select-item.ant-select-item-group {
  padding: 20px 5 0 0 !important;
}

.m-review-text {
  color: #183362;
  font-size: 18px;
}

.m-review-text-bold {
  color: #183362;
  font-size: 18px;
  font-weight: 700;
}

.ant-drawer-header {
  display: none !important;
}

.m-input-title {
  font-size: 24px;
  color: #183362;
}

.m-input-label {
  font-size: 12px;
  color: #686868;
  &.m-input-label-success {
    color: #87d47d !important;
  }
}

.mk-header {
  .m-title {
    color: $--o-color-text-brand;
    font-size: 30px;
    font-weight: 400;
    margin-bottom: 0;
  }

  .m-found-notifications {
    font-size: 14px;
    font-weight: 400;
    color: $--o-color-text-information;
    text-align: right;

    span {
      color: $--o-color-text-brand;
      font-weight: 700;
    }
  }
}

.ant-table-wrapper.mk-table .ant-table-thead > tr > th {
  color: $--o-color-text-brand;
}

.ant-divider {
  background-color: $--o-color-border;

  &.ant-divider-horizontal {
    margin: 20px 0px;
  }
}

.ant-space-compact {
  .ant-btn.ant-btn-default {
    color: $--o-color-text-brand-secondary;
    font-weight: 700;
    height: auto;
  }
}

.ant-modal .button-align-right .tgg-button-wrapper {
  justify-content: right;
}

label {
  color: $--o-color-text-information-onsecondary;
}

.anticon.deafult-color {
  color: $--o-color-icon;
}

.ant-select {
  &.largeHeight {
    .ant-select-selector {
      height: 48px;
      padding: 10px 11px;
    }
    .ant-select-selection-search-input {
      height: 48px !important;
    }
  }
}

.ant-select {
  &.extralargeHeight {
    .ant-select-selector {
      height: 56px;
      padding: 16px 11px;
    }
    .ant-select-selection-search-input {
      height: 56px !important;
    }
    .ant-select-selection-item {
      line-height: inherit;
    }
  }
}

.ant-table-cell {
  .truncate-text-location-cell {
    display: flex;

    .truncate-text-location {
      white-space: nowrap;
      width: 190px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: inline-block;
      line-height: 24px;
    }
  }

  .btn-more-details {
    color: $--o-color-text-brand-secondary;
    background-color: $--o-color-bg-canvas;
    border-color: $--o-color-bg-canvas;
    font-weight: 700;
    height: auto;
  }
}

.col-align-right .m-styled-btn-container {
  align-items: end;
}
