$iz-dark-blue: #183362;
$iz-light-blue: #009dd3;
$iz-yellow-accent: #ffce00;

$primary: #009dd3;

@font-face {
  font-family: 'Libre-Franklin';
  src: url('../fonts/LibreFranklin/LibreFranklin-Regular.ttf');
  font-weight: 400;
}

@font-face {
  font-family: 'Libre-Franklin';
  src: url('../fonts/LibreFranklin/LibreFranklin-Medium.ttf');
  font-weight: 500;
}

@font-face {
  font-family: 'Libre-Franklin';
  src: url('../fonts/LibreFranklin/LibreFranklin-Bold.ttf');
  font-weight: 700;
}

@font-face {
  font-family: 'Libre-Franklin';
  src: url('../fonts/LibreFranklin/LibreFranklin-MediumItalic.ttf');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Libre-Franklin';
  src: url('../fonts/LibreFranklin/LibreFranklin-Light.ttf');
  font-weight: 300;
}

.m-row-container {
  position: relative;

  padding: 24px 0;
}

html,
body {
  margin: 0;
  height: 100%;

  font-family: 'Libre-Franklin';
  font-weight: 400;

  background-color: #f1f1f1;
}

#root {
  height: 100%;
}

.ant-table-cell {
  text-align: left !important;
}

.ant-picker.m-placeholder-hidden,
input:not(:placeholder-shown),
textarea:not(:placeholder-shown) {
  border-color: #183362 !important;
}
.ant-modal {
  .ant-picker.m-placeholder-hidden,
  input:not(:placeholder-shown),
  textarea:not(:placeholder-shown) {
    border-color: #d9d9d9 !important;
  }
}

// margin 5

.m-mb-5 {
  margin-bottom: 5px;
}

// margin 5

// margin 10

.m-mt-10 {
  margin-top: 10px;
}

.m-mt-20 {
  margin-top: 20px;
}

.m-mt-40 {
  margin-top: 40px;
}

.m-mb-10 {
  margin-bottom: 10px;
}

.m-mr-10 {
  margin-right: 10px;
}

.m-ml-10 {
  margin-left: 10px;
}

// margin 10

// margin 15

.m-mt-15 {
  margin-top: 15px;
}

.m-mb-15 {
  margin-bottom: 15px;
}

.m-ml-15 {
  margin-left: 15px;
}

.m-mr-15 {
  margin-right: 15px;
}

// margin 15

// margin 20

.m-mb-20 {
  margin-bottom: 20px;
}
.m-mb-24 {
  margin-bottom: 24px;
}

.m-mt-20 {
  margin-top: 20px;
}

.m-mr-20 {
  margin-right: 20px;
}

.m-ml-20 {
  margin-left: 20px;
}

// margin 20

// margin 30

.m-mb-30 {
  margin-bottom: 30px;
}

.m-mt-30 {
  margin-top: 30px;
}

.m-mr-30 {
  margin-right: 30px;
}

.m-ml-30 {
  margin-left: 30px;
}

// margin 20

// margin 40

.m-mt-40 {
  margin-top: 40px;
}

.m-mb-40 {
  margin-bottom: 40px;
}

.m-ml-40 {
  margin-left: 40px;
}

.m-mr-40 {
  margin-right: 40px;
}

// margin 40

// margin 60

.m-mb-60 {
  margin-bottom: 60px;
}

// margin 60

.m-dark-blue {
  color: #183362;
}

.m-fs-12 {
  font-size: 12px;
}

.m-weight-400 {
  font-weight: 400 !important;
}

.m-weight-500 {
  font-weight: 500 !important;
}

.m-weight-600 {
  font-weight: 600 !important;
}

.m-weight-700 {
  font-weight: 700 !important;
}

.m-btn-transparent {
  background-color: transparent !important;
  color: #009dd3;
  border-color: #009dd3 !important;

  &:hover:not(:disabled) {
    border-color: #ffce00 !important;
  }
}

.m-dropdown:disabled {
  background: #e8e8e8;
}

.m-link-disabled {
  opacity: 0.5;

  :hover:not(:disabled) {
    cursor: not-allowed;
  }
}

.m-error {
  font-size: 18px;
  color: #bc2424;
}

.m-error-label {
  font-size: 14px;
  color: #bc2424;
}

.m-error {
  color: #bc2424;
}

.m-table-footer-row {
  display: grid;
  justify-content: center;
  align-items: center;

  height: 59px;
  background-color: #fff;
  border-bottom: 1px solid #d0d0d0;

  .m-add-step-icon {
    svg {
      height: 25px;
      width: 25px;
    }
  }
}

.m-right-icon {
  position: absolute;
  bottom: 12px;
  right: -40px;
}

.ant-popover-inner {
  padding: 0 !important;
}

.ant-popover-title {
  color: #183362 !important;
  font-weight: 600 !important;
  font-size: 18px !important;
  margin: 0 !important;
  padding: 25px 0 25px 15px !important;
}

// ANTD DROPDOWNS OVERRIDE CSS

.ant-select-item-option-active {
  background-color: transparent !important;
}

.ant-select-dropdown .ant-select-item:first-of-type {
  height: 35px !important;
}

.ant-select-dropdown .ant-select-item {
  padding: 5px 12px !important;
}

.ant-select-item.ant-select-item-group {
  padding: 20px 5 0 0 !important;
}

// ANTD DROPDOWNS OVERRIDE CSS

.m-view-only-dropdown {
  background-color: #fff;

  .m-option-container {
    border: 1px solid #183362;

    &:hover {
      border-color: #009dd3;
      cursor: pointer;
    }
  }
}

.m-option-container {
  display: flex;
  flex-direction: column;
  gap: 5px;

  border: 1px solid #ddd;
  padding: 20px 20px;
  height: 100%;
  border-radius: 5px;

  .m-overflow-col {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:hover:not(.m-disabled) {
    border-color: #009dd3;
    transition: ease-in-out, 3ms;

    .m-icon,
    .m-option-highlight,
    .m-option-sub-value {
      filter: invert(43%) sepia(66%) saturate(1530%) hue-rotate(164deg) brightness(92%) contrast(101%); // Light blue color.
    }

    .m-icon svg {
      color: #009dd3;
    }
  }

  &.m-disabled {
    cursor: default;
  }

  .m-option-highlight,
  .m-option-sub-title,
  .m-option-sub-value {
    font-size: 14px;
  }

  .m-option-highlight {
    font-weight: 700;
    color: #183362;
  }

  .m-option-sub-title {
    color: #898989;
    font-weight: 300;
  }

  .m-option-sub-value {
    color: #183362;
    font-weight: 400;
  }
}

.m-text {
  color: #183362;
  font-size: 18px;
}

.m-text-italic {
  font-style: italic;
}

.center-container {
  position: relative !important;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

button.m-btn-primary {
  color: #fff !important;
  height: 48px;
  min-width: 199px;
  background-color: #009dd3;
  text-transform: uppercase !important;

  border: none;
  border-radius: 5px;
}

button.m-btn-primary[disabled] {
  opacity: 0.6;
}

button.m-btn-primary[disabled]:hover {
  background-color: #009dd3;
  opacity: 0.6;
}

button.m-btn-primary:hover {
  background-color: #ffce00;
  transition-timing-function: ease-in;
  transition: 0.3s;
}

.ant-modal .ant-modal-content {
  padding: 48px 93px;
}
