@import '../../../assets/styles/theme.scss';

.m-modal {
  text-align: center;
  color: $iz-dark-blue;
}

.m-modal-title {
  @extend .m-modal;
  font-size: 30px;
}

.m-modal-text {
  @extend .m-modal;
  font-size: 16px;
  margin-bottom: 40px;
}

.m-modal-btn {
  height: 48px;
  min-width: 167px;
  width: auto;
  border: 1px solid $iz-light-blue;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.m-modal-cancel-btn {
  @extend .m-modal-btn;
  color: $iz-light-blue;
}

.m-modal-submit-btn {
  @extend .m-modal-btn;
  background: $iz-light-blue;
}

.m-modal-datepicker {
  height: 56px;
  width: 189px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  color: $iz-light-blue;
}

.m-modal-datepicker > .ant-picker-input {
  > input {
    font-size: 14px;
    color: $iz-light-blue;
  }
  > input::placeholder {
    font-size: 14px;
    color: $iz-light-blue;
  }
  > span {
    color: $iz-light-blue;
  }
}
