@import '../../../assets/styles/theme.scss';

.m-modal {
  text-align: center;
  color: $iz-dark-blue;
}

.m-modal-title {
  @extend .m-modal;
  font-size: 30px;
}

.m-modal-text {
  @extend .m-modal;
  font-size: 16px;
  margin-bottom: 40px;
}

.m-modal-btn {
  height: 48px;
  min-width: 167px;
  width: auto;
  border: 1px solid $iz-light-blue;
  border-radius: 5px;
  font-size: 18px;
  font-weight: 700;
  text-transform: uppercase;
}

.m-modal-cancel-btn {
  @extend .m-modal-btn;
  color: $iz-light-blue;
}

.m-modal-submit-btn {
  @extend .m-modal-btn;
  background: $iz-light-blue;
}

.m-modal-input {
  height: 56px;
  width: 100%;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
  color: $iz-dark-blue;
  > input::placeholder {
    font-size: 14px;
    color: $iz-light-blue;
  }
}

.m-modal-input-label {
  font-size: 12px;
  font-weight: 400;
  color: #686868;
  &.m-error {
    color: #bc2424;
  }
}

.m-modal-input-disabled {
  color: #183362;
  background-color: #ffffff;
}

.m-modal-input > .ant-picker-input {
  > input {
    font-size: 14px;
    color: $iz-dark-blue;
  }
  > input::placeholder {
    font-size: 14px;
    color: $iz-light-blue;
  }
  > span {
    color: $iz-light-blue;
  }
}

.m-modal-link {
  color: $iz-light-blue;
}

.m-modal-error {
  color: #bc2424;
  font-size: 16px;
}

.ant-btn-link:disabled {
  color: gray !important;
}
